import logo from "./logo.svg";
import "./App.css";

import MainPage from "./screens/MainPage";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./screens/LoginPage";
import { AuthProvider } from "./hook/useAuth";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import AdminPanel from "./screens/AdminPanel";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminPanel />
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
