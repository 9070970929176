import React, { useState, useEffect } from "react";

import SearchBar from "../components/Search";
import Header from "../components/Header";
import AppCard from "../components/AppCard";

const GamesPage = ({ handleItemClick, numbers }) => {
  const [filteredGames, setFilteredGames] = useState([]);
  const [query, setQuery] = useState("");

  function querySearched(q) {
    setQuery(q);
  }

  const searchItems = (array, query) => {
    console.log(query);
    if (!query) return array; // Return the original array if no search term is provided

    const lowercasedTerm = String(query).toLowerCase();

    return array.filter((item) => {
      return (
        String(item.name).toLowerCase().includes(lowercasedTerm) ||
        String(item.description).toLowerCase().includes(lowercasedTerm) ||
        String(item.author).toLowerCase().includes(lowercasedTerm)
      );
    });
  };

  const filteredItems = searchItems(numbers, query);

  useEffect(() => {
    // Filter the games and copy the result to filteredGames state
    const newFilteredGames = numbers.filter((game) => game.category === "1");
    setFilteredGames(newFilteredGames.slice()); // Create a copy using slice()
  }, [numbers]);

  return (
    <div className="min-h-screen lg:mx-auto lg:w-[500px] lg:border-[1px] drop-shadow">
      <Header />
      <SearchBar querySearched={querySearched} />
      <div className="mt-6 space-y-4 mb-4">
        {filteredItems
          .slice(0, 20)
          .reverse()
          .map((app, index) => (
            <AppCard
              key={index}
              app={app}
              onGameClicked={() => handleItemClick(app)}
            />
          ))}
      </div>
    </div>
  );
};

export default GamesPage;
