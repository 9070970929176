import axios from "axios";
import React, { useState } from "react";
import { GET_BASE_URL } from "../utils/Constants";

const ModifyGame = () => {
  const [isForIosCheck, setIsForIosCheck] = useState(false);
  const [isForAndroidCheck, setIsForAndroidCheck] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageName, setImageName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    description: "",
    author: "",
    category: "",
    size: "",
    rating: "",
    reviews: "",
    link: "",
    download: "",
  });

  const handleSearch = async () => {
    try {
      const response = await axios.get(GET_BASE_URL() + "search_game.php", {
        params: { query: searchQuery },
      });

      if (response.data && response.data.success) {
        const data = response.data.game;
        console.log(data.id); // Assuming the response has a "game" object with details
        setFormData({
          id: data.id,
          name: data.name,
          description: data.description,
          author: data.author,
          category: data.category,
          size: data.size,
          rating: data.rating,
          reviews: data.reviews,
          link: data.link,
          download: data.download,
        });
        setIsForIosCheck(data.isForIos === 1);
        setIsForAndroidCheck(data.isForAndroid === 1);
        setImageName(data.image);
        alert("Data loaded successfully");
      } else {
        alert("Game not found");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Error fetching data");
    }
  };

  const handleSubmitNew = async (event) => {
    event.preventDefault();
    const sendData = {
      ...formData,
      image: imageName,
      isForIos: isForIosCheck ? 1 : 0,
      isForAndroid: isForAndroidCheck ? 1 : 0,
    };

    if (imageName.length === 0) {
      alert("Image not uploaded");
      return;
    }

    console.log(sendData);
    axios
      .post(GET_BASE_URL() + "modify_game.php", sendData)
      .then((result) => {
        console.log(result);
        if (result.status === 200) {
          alert("Success adding data");
        } else {
          alert("Error adding data");
        }
      })
      .catch((error) => {
        console.log(error.toJSON());
      });
  };

  const handleImageFile = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setImageFile(file);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image_path", imageFile);

    axios
      .post(GET_BASE_URL() + "upload.php", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((result) => {
        if (result.data.length > 0) {
          alert("Image uploaded");
          setImageName(result.data);
        } else {
          alert("Error uploading image");
        }
      });
  };

  const handleImageDeletion = () => {
    console.log(imageName);
    axios
      .post(GET_BASE_URL() + "delete_image.php", { image_name: imageName })
      .then((response) => {
        setImageName("");
        console.log(response.data);
      })
      .catch((error) => console.error(error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="mx-auto p-4 bg-white  max-w-lg sm:max-w-3xl rounded-xl">
      {/* Search Bar */}

      <div className="mb-4 medium text-xl"> Search game you want to modify</div>
      <div className="flex flex-col sm:flex-row items-center mb-6 gap-4">
        <input
          type="text"
          placeholder="Search game by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="p-2 border border-secondary rounded-lg w-full sm:w-2/3"
        />
        <button
          onClick={handleSearch}
          className="bg-blue-500 px-3 py-2 text-white rounded-lg hover:bg-blue-600 transition"
        >
          Search
        </button>
      </div>

      <div className="h-[1px] bg-secondary mb-4"></div>

      <div className="flex flex-col sm:flex-row items-center sm:justify-start gap-4">
        <label
          className="block text-lg font-bold mb-1 sm:mr-4"
          htmlFor="image_path"
        >
          Image Path
        </label>
        <div className="flex flex-row items-center gap-2 w-full sm:w-2/3">
          <input
            type="file"
            name="image_path"
            id="image_path"
            onChange={handleImageFile}
            className="w-full p-2 border border-secondary rounded-lg"
            accept="image/*"
          />

          {imageName.length > 0 ? (
            <div className="rounded-full bg-green-500 h-8 w-8"></div>
          ) : (
            <button
              onClick={handleImageUpload}
              className="bg-blue-500 px-3 py-2 text-white rounded-lg hover:bg-blue-600 transition"
            >
              Upload
            </button>
          )}

          <button
            onClick={handleImageDeletion}
            className="bg-blue-500 px-3 py-2 text-white rounded-lg hover:bg-blue-600 transition"
          >
            Remove Image
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmitNew} className="space-y-4 mt-4">
        {[
          {
            label: "Name",
            name: "name",
            type: "text",
            placeholder: "Enter name",
          },
          {
            label: "Description",
            name: "description",
            type: "textarea",
            placeholder: "Enter description",
          },
          {
            label: "Author",
            name: "author",
            type: "text",
            placeholder: "Enter author",
          },
          {
            label: "Category",
            name: "category",
            type: "text",
            placeholder: "Mod / Game / App",
          },
          { label: "Size", name: "size", type: "text", placeholder: "300MB" },
          { label: "Rate", name: "rating", type: "number", placeholder: "4.2" },
          {
            label: "Reviews",
            name: "reviews",
            type: "text",
            placeholder: "20k+",
          },
          {
            label: "Redirect Link",
            name: "link",
            type: "text",
            placeholder: "Enter redirect link",
          },
          {
            label: "Download",
            name: "download",
            type: "text",
            placeholder: "200k+",
          },
        ].map((field, index) => (
          <div
            key={index}
            className="flex flex-col sm:flex-row items-center gap-2 sm:justify-start"
          >
            <label className="block text-lg font-bold w-full sm:w-1/4 text-left sm:text-right pr-4">
              {field.label}
            </label>
            {field.type === "textarea" ? (
              <textarea
                name={field.name}
                placeholder={field.placeholder}
                value={formData[field.name]}
                onChange={handleInputChange}
                className="w-full sm:w-2/3 p-2 border border-secondary rounded-lg"
              />
            ) : (
              <input
                type={field.type}
                name={field.name}
                placeholder={field.placeholder}
                value={formData[field.name]}
                step="any"
                onChange={handleInputChange}
                className="w-full sm:w-2/3 p-2 border border-secondary rounded-lg"
                required={field.label !== "Size"}
              />
            )}
          </div>
        ))}

        <div className="flex flex-col sm:flex-row items-center gap-4 sm:justify-start">
          <label className="block text-lg font-bold">Operating System</label>
          <div className="flex items-center gap-4 sm:gap-8">
            <div className="flex items-center gap-2">
              <span>iOS</span>
              <input
                type="checkbox"
                name="isForIos"
                checked={isForIosCheck}
                onChange={() => setIsForIosCheck(!isForIosCheck)}
                className="w-6 h-6"
              />
            </div>
            <div className="flex items-center gap-2">
              <span>Android</span>
              <input
                type="checkbox"
                name="isForAndroid"
                checked={isForAndroidCheck}
                onChange={() => setIsForAndroidCheck(!isForAndroidCheck)}
                className="w-6 h-6"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center sm:justify-start mt-4">
          <button
            type="submit"
            className="w-full sm:w-1/3 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition justify-center"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ModifyGame;
