import React from "react";
import downloadIcon from "../assets/res/download.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GET_BASE_URL } from "../utils/Constants";

function AppCard({ app, onGameClicked }) {
  return (
    <div className="flex items-center bg-white p-2 rounded-xl mx-4 shadow-primary shadow-md border-[1px] border-primaryWhite">
      <div className="flex flex-row">
        <div className="w-12 h-12 bg-gray-300 rounded-xl mr-4">
          <LazyLoadImage
            src={GET_BASE_URL() + "images/" + app.image}
            className="w-12 h-12 bg-gray-300 rounded-xl mr-4"
          />
        </div>
        <div className="flex-grow">
          <h2 className="text-lg  medium text-m ">{app.name}</h2>

          <div className="flex flex row  items-center">
            <div className="text-gray-900 medium text-sm ">⭐ {app.rating}</div>
            <div className="medium text-xs rounded-xl border-secondary border-[2px] px-2 ml-2">
              {app.category}
            </div>
          </div>

          <p className=" medium text-sm text-secondary">
            {app.isForIos && "iOS "} {app.isForIos && app.isForAndroid && "/"}{" "}
            {app.isForAndroid && "Android"}
          </p>
        </div>
      </div>

      <button
        className="ml-auto  h-[40px] w-[40px] bg-primary text-white pl-[10px] rounded-full"
        onClick={onGameClicked}
      >
        <img src={downloadIcon} className="h-[20px] w-[20px]" />
      </button>
    </div>
  );
}

export default AppCard;
