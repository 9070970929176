import axios from "axios";
import React, { useState } from "react";
import { GET_BASE_URL } from "../utils/Constants";
import AddGames from "./AddGames";
import ModifyGame from "./ModifyGame";

const AdminPanel = () => {
  const [selectedPage, setSelectedPage] = useState("add_game");

  return (
    <div className="flex flex-col">
      <div className="flex flex-row mx-auto py-10 semiBold text-4xl bg-dark text-white w-full items-center justify-center">
        Admin Dashboard
      </div>

      <div className="flex flex-row">
        <div className="flex flex-col semiBold text-3xl bg-dark h-screen text-white text-center ">
          <div>Mods Jungle</div>

          <div className="h-[40px]"></div>

          <div
            className={`px-6 py-4 text-center medium text-white text-2xl ${
              selectedPage === "add_game" ? "bg-primary" : "bg-dark"
            }`}
            onClick={() => {
              setSelectedPage("add_game");
            }}
          >
            Add Game
          </div>
          <div className="h-[1px] bg-primaryWhite"></div>
          <div
            className={` px-6 py-4  text-center text-white medium text-2xl ${
              selectedPage === "modify_game" ? "bg-primary" : "bg-dark"
            }`}
            onClick={() => {
              setSelectedPage("modify_game");
            }}
          >
            Modify Game
          </div>
        </div>

        {selectedPage === "add_game" && <AddGames />}
        {selectedPage === "modify_game" && <ModifyGame />}
      </div>
    </div>
  );
};

export default AdminPanel;
