import React from "react";
import Lottie from "lottie-react";
import logo from "../assets/lottie/logo.json";
import logoBackground from "../assets/res/logo_background.png";

function Header() {
  return (
    <div className="bg-primary p-6 text-center bottom-0  items-center flex flex-col h-[175px] w-[100%]  shadow-sm rounded-br-[20%] rounded-bl-[20%] ">
      <div className="flex -mt-[10px]">
        <img src={logoBackground} className="w-[100px] h-[60px]" />
        <Lottie
          animationData={logo}
          loop={true}
          className="w-[100px] h-[60px] -ml-[100px] mt-[35px]"
        />
      </div>

      <h1 className="text-2xl bold text-l text-dark -mt-[4px]">Mods Jungle</h1>
    </div>
  );
}

export default Header;
