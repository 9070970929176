import { React, useState, useEffect, useCallback, useRef } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

let intervalID;

function Loading({ title, link, closeLoading }) {
  const isHandleExternalRef = useRef(false);
  const [percentage, setPercentage] = useState(0);
  const [progressName, setProgressName] = useState("Connecting.....");
  const [isHandleExternal, setIsHandleExternal] = useState(false);

  useEffect(() => {
    // THIS IS THE MAGIC PART
    isHandleExternalRef.current = isHandleExternal;
  }, [isHandleExternal]);

  function myCallback() {
    // Your code here
    // Parameters are purely optional.

    if (percentage == 100) {
      setProgressName("Done");
      clearInterval(intervalID);

      if (!isHandleExternalRef.current) {
        setTimeout(redirectToOtherPage, 2000);
      }
    } else {
      setPercentage(percentage + 1);
      if (percentage < 20) {
        setProgressName("Connecting.....");
      } else if (percentage < 80) {
        setProgressName("Downloading.....");
      } else if (percentage < 99) {
        setProgressName("Finalizing.....");
      }
    }
  }

  function redirectToOtherPage() {
    setIsHandleExternal(true);

    console.log(isHandleExternalRef.current);
    if (isHandleExternalRef.current) {
      window.open(link, "_self");
    }

    closeLoading();
  }

  useEffect(() => {
    intervalID = setInterval(myCallback, 300);
    return () => clearInterval(intervalID);
  });

  return (
    <div className=" flex flex-col py-8">
      <div className="mx-auto mb-6 semiBold text-2xl text-dark">{title}</div>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        className="h-[150px] w-[150px] medium"
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)

          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: "round",

          // Text size

          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 0.5,

          // Can specify path transition in more detail, or remove it entirely
          // pathTransition: 'none',

          // Colors
          pathColor: `rgba(108, 184, 82, ${percentage / 50})`,
          textColor: "#00bef2",
          trailColor: "#e8ede6",
          backgroundColor: "#e8ede6",
        })}
      />
      <div className="mx-auto mt-4 animate-pulse medium text-green-900 text-xl">
        {progressName}
      </div>
    </div>
  );
}

export default Loading;
