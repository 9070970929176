import { React, useState, useEffect, useCallback } from "react";
import backIcon from "..//assets/res/back_black.svg";
import appleIcon from "..//assets/res/apple_black.svg";
import androidIcon from "..//assets/res/android_green.svg";
import downloadIcon from "../assets/res/download.svg";
import { GET_BASE_URL } from "../utils/Constants";

const InfoBlock = ({ showLeftDivier = false, title, value }) => {
  return (
    <div className="flex flex-row">
      {showLeftDivier && (
        <div className="h-12 w-[0.5px] bg-gray-800 mr-6 mt-1"></div>
      )}
      <div className="flex flex-col items-center  ">
        <div className="text-black-600 medium tracking-wide  text-center text-xs">
          {title}
        </div>
        <div className="text-primary medium text-base  text-center tracking-wide">
          {value}
        </div>
      </div>
      <div className="h-12 w-[0.5px] bg-gray-800 ml-6 mt-1"></div>
    </div>
  );
};

const InfoBlockPlatform = ({ title, isForAndroid, isForIos }) => {
  return (
    <div className="flex flex-row">
      <div className="flex flex-col items-center  ">
        <div className="text-black-600 medium tracking-wide  text-center text-xs">
          {" "}
          {title}
        </div>
        <div className="flex flex-row justify-center mt-1">
          {isForAndroid && <img src={androidIcon} className="h-5 w-5" />}
          {isForIos && <img src={appleIcon} className="h-5 w-5 ml-2" />}
        </div>
      </div>
      <div className="h-12 w-[0.5px] bg-gray-800 ml-6 mt-1"></div>
    </div>
  );
};

function PageDetails({ app, handleClose, openLoading }) {
  return (
    <div className=" bg-white absolute inset-0 flex flex-col  z-10 p-4 h-full lg:mx-auto lg:w-[500px] lg:border-[1px] drop-shadow h-max">
      <img
        className="h-8 w-8 object-cover object-center  h-[22px] w-[22px]"
        src={backIcon}
        alt="nature image"
        onClick={handleClose}
      />
      <h1 className="semiBold text-2xl mx-auto text-center mt-4">{app.name}</h1>
      <div className="flex flex-row items-baseline mx-auto mt-2">
        <div className="medium text-xs rounded-xl border-secondary border-[2px] px-2 h-[19px]">
          {app.category}
        </div>
      </div>
      <div className="rounded-lg overflow-hidden mb-4 mt-4 w-48 h-48 mx-auto  bg-blue-500 border-[1px] border-primary">
        <img
          src={GET_BASE_URL() + "images/" + app.image}
          alt={app.name}
          className="w-48 h-48 object-cover"
        />
      </div>
      <div className="flex flex-row mt-4  bg-red overflow-scroll no-scrollbar ">
        <InfoBlock showLeftDivier={true} title="RATING" value={app.rating} />

        <div className="w-[0.5px] bg-gray-800 my-2 mx-4"></div>
        <InfoBlock title="DEVELOPER" value={app.author} />
        <div className="w-[0.5px]  bg-gray-800 my-2  mx-4"></div>
        <InfoBlockPlatform
          title="PLATFORM"
          isForAndroid={app.isForAndroid}
          isForIos={app.isForIos}
        />
        <div className="w-[0.5px]  bg-gray-800 my-2  mx-4"></div>
        <InfoBlock title="DOWNLOAD" value={app.download} />
        <div className="w-[0.5px]  bg-gray-800 my-2  mx-4"></div>
        <InfoBlock title="SIZE" value={app.size} />
        <div className="w-[0.5px]  bg-gray-800 my-2  mx-4"></div>
      </div>

      <p className="text-gray-900 mt-4 mb-4 text-m medium">{app.description}</p>
      <button
        onClick={openLoading}
        className="w-full bg-primary text-white py-2 rounded-lg flex items-center justify-center space-x-2 mt-6 semiBold"
      >
        <span>Download</span>
        <img src={downloadIcon} className="h-[20px] w-[20px]" />
      </button>
      <div className="h-[300px]"></div>
    </div>
  );
}

export default PageDetails;
