import React, { useState } from "react";
import searchIcon from "..//assets/res/search_black.svg";

function SearchBar({ querySearched }) {
  const [query, setQuery] = useState("");

  return (
    <div className="-mt-6 mx-9 rounded-xl bg-white shadow-lg flex flex-row items-center rounded-md border rounded-xl medium border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-300">
      <div className="h-6 w-6  ml-4">
        <img src={searchIcon} className="h-6 w-6"></img>
      </div>
      <input
        type="text"
        placeholder="Search game/app/mod"
        className="w-full p-2  rounded-xl medium border-gray-300 focus:outline-none "
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
          querySearched(e.target.value);
        }}
      />
    </div>
  );
}

export default SearchBar;
