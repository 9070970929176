import React, { useEffect, useState } from "react";
import axios from "axios";
import GamesPage from "./GamesPage";
import { Container, CircularProgress, Drawer, Button } from "@mui/material";
import { GET_BASE_URL } from "../utils/Constants";
import PageDetails from "./PageDetails";
import Loading from "../components/Loading";

const MainPage = () => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [page, setPage] = useState("Games");

  const fetchData = async () => {
    try {
      // Example API call (you can replace this with your actual API)
      const response = await axios.get(GET_BASE_URL() + "get_game.php");
      setItems(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleItemClick = (item) => {
    console.log(item.name);
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  const openLoadingBottom = () => {
    setOpenLoading(true);
  };

  const closeLoadingBottom = () => {
    setOpenLoading(false);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-200 fixed inset-0 z-50">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col align-middle ">
        {!open && (
          <GamesPage handleItemClick={handleItemClick} numbers={items} />
        )}

        {open && (
          <PageDetails
            app={selectedItem}
            handleClose={handleClose}
            openLoading={openLoadingBottom}
          />
        )}

        {selectedItem != null && (
          <Drawer anchor={"bottom"} open={openLoading}>
            <Loading
              closeLoading={closeLoadingBottom}
              title={selectedItem.name}
              link={selectedItem.link}
            />
          </Drawer>
        )}
      </div>
    </>
  );
};

export default MainPage;
